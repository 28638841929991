@font-face {
	font-family: 'Montserrat'; 
	src: url(/src/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf); 
}

.container{
    width: 100%;
    height: 130vh;
    background-color: #171717;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 35vh;
    position: relative;
    overflow: hidden;
    
}
.container::before{
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    /*  */
    top:-20vh;
    position: absolute;
    background:radial-gradient(rgb(18, 42, 75),transparent,transparent);
    /* animation: animate 10.5s linear infinite;; */
    
}
@keyframes animate
{
    0%
    {
        transform: translate(0vw,-50vh);
    }
    20%
    {
        transform: translate(-30vw,-20vw);
    }
    40%
    {
        transform: translate(30vw,-20vw);
    }
    60%
    {
        transform: translate(-30vw,-90vh);
    }
    80%
    {
        transform: translate(30vw,-90vh);
    }
    100%
    {
        transform: translate(0vw,-50vh);
    }
}
.heroContainer{
    width: 100%;
    height: auto;
    display: flex;
    gap: 3vw;
    justify-content: center;
    align-items: center;
}
.imgContainer{
    width: 55%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.logo{
    width:90%;
    z-index: 1;
}
.headerContainer{
    width: 45%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.header{
    width: 90%;
    height: auto;
    font-family: Montserrat;
    font-weight: bold;
    color: var(--text-color-link);
    font-size:3vw;
    z-index: 1;
}
.header span{
    color:#3A4CFF ;
}
.subtract{
    position: absolute;
    top: 50vh;
    right: 0;
    width: 100%;
    z-index: 0;
}
.description{
    width: 90%;
    height: auto;
    background-color: rgb(23, 28, 37);
    opacity: 1;
    z-index: 2;
    padding: 5vh 3vw;
    color: white;
    margin-top: 30vh;
    font-size: 1.8vw;
    text-align: center;
    border-radius: 5vh;
    font-family: Montserrat;
    font-weight: 100;
}

.description p{
    opacity: .8;
}
.ellipce{
    height: 100vh;
    top:0;
    right: 0;
    position: absolute;
}
.link{
    font-size: 2.5vw;
    color:white;
    font-family: Montserrat;
    font-weight: 700;
    z-index: 11;
    margin-top: 5vw;
    cursor: pointer;
    opacity: .8;
    transition: all.3s;
    background-color: rgba(128, 128, 128, 0.103);
    padding:1.5vw 4vw;
    border-radius: 15vh;
}
.link:hover{
    transform: scale(102%);
    opacity: 1;
    background-color:#2B3BD9;
}
@media (max-width: 780px){
    .link{
        width: 85%;
        box-sizing: border-box;
        text-align: center;
        font-size: 7.5vw;
        color:white;
        font-family: Montserrat;
        font-weight: 700;
        z-index: 11;
        margin-top: 10vw;
        cursor: pointer;
        opacity: .8;
        transition: all.3s;
        background-color: rgba(128, 128, 128, 0.103);
        padding:5vw 8vw 9vw 8vw;
        border-radius: 5vw;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .container{
        height: auto;
        justify-content: flex-start;
        padding-top: 35vh;
        padding-bottom: 5vh;
    }
    .heroContainer{
        gap: 0vw;
        flex-direction: column;
    }
    .imgContainer{
        width: 100%;
        height: auto;
        justify-content: center;
    }
    .logo{
        width:80%;
    }
    .headerContainer{
        width: 100%;
        height: auto;
        justify-content: center;
        margin-top: 5vh;
    }
    .header{
        width: 90%;
        height: auto;
        font-size:7vw;
        text-align: center;
    }
    .description{
        padding: 5vh 3vw;
        margin-top: 20vh;
        font-size: 5vw;
        border-radius: 5vh;
    }
}