.container{
    width: 100%;
    height: auto;
    padding: 10vh 0 20vh 0;
    gap: 10vh;
    background-color: #171717;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
}
.container::before{
    z-index: 0;
    content: '';
    /*  */
    top:-40vh;
    width: 100vw;
    height: 100vw;
    position: absolute;
    background:radial-gradient(rgb(6, 24, 50),transparent,transparent);
    /* animation: animate 10.5s linear infinite;; */
    
}
@keyframes animate
{
    0%
    {
        transform: translate(0vw,-50vh);
    }
    20%
    {
        transform: translate(-30vw,-30vw);
    }
    40%
    {
        transform: translate(30vw,-30vw);
    }
    60%
    {
        transform: translate(-30vw,-70vh);
    }
    80%
    {
        transform: translate(30vw,-70vh);
    }
    100%
    {
        transform: translate(0vw,-50vh);
    }
}
.nestedContainer{
    width: 100%;
    height: auto;
    display: flex;
    gap: 5vw;
    z-index: 1;
}
.imgContainer{
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.img{
    width: 70%;
}
.textContainer{
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: Montserrat;
    font-weight: bold;
}
.description{
    width: 90%;
    height: auto;
    color: var(--text-color-link);
    font-size:2.1vw;
    z-index: 1;
    font-family: Montserrat;
    font-weight: bold;
    text-align: right;
}
.description1{
    width: 90%;
    height: auto;
    color: var(--text-color-link);
    font-size:2.1vw;
    z-index: 1;
    font-family: Montserrat;
    font-weight: bold;
    text-align: left;
}
.description span{
    color:#3A4CFF ;
}
.description1 span{
    color:#3A4CFF ;
}
.imgContainer1{
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.textContainer1{
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.substrat{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}

@media (max-width: 780px){
    .container{
        padding: 10vh 0;

    }
    .nestedContainer{
        flex-direction: column;
        gap: 7vw;
        border: 1px solid rgb(35, 45, 55);
        width: 90%;
        border-radius: 10vw;
        padding: 7vh 0;
        backdrop-filter: blur(45px);
    }
    .imgContainer{
        width: 100%;
        justify-content: center;
        order: 1; /* Меняем порядок отображения контейнера с изображением */
    }
    .img{
        width: 80%;
    }
    .textContainer{
        width: 100%;
        justify-content: center;
        order: 2; /* Меняем порядок отображения текстового контейнера */
    }
    .description{
        width: 85%;
        font-size:5vw;
        text-align: center;
    }
    .imgContainer1{
        width: 100%;
        justify-content: center;
    }
    .img1{
        width: 80%;
    }
    .textContainer1{
        width: 100%;
        justify-content: center;
    }
    .description1{
        width: 85%;
        font-size:5vw;
        text-align: center;
    }
}