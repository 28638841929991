


.container{
    width: 100%;
    height: auto;
    background-color: #171717;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15vh;
    position: relative;
    overflow: hidden;
    
}
.container::before{
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    /*  */
    top:0vh;
    position: absolute;
    background:radial-gradient(rgb(18, 42, 75),transparent,transparent);
    /* animation: animate 10.5s linear infinite;; */
    
}
.img{
    height: 80vh;
    z-index: 11;
    margin-top: 10vh;
}
.header{
    height: auto;
    font-family: Montserrat;
    font-weight: bold;
    color: var(--text-color-link);
    font-size:3.5vw;
    z-index: 11;
   }
.description{
    width: 80%;
    text-align: center;
    height: auto;
    font-family: Montserrat;
    font-weight: 300;
    opacity: .8;
    color: var(--text-color-link);
    font-size:1.3vw;
    z-index: 11;
    margin-top: 10vh;
   }
.description2{
    width: 50%;
    text-align: center;
    height: auto;
    font-family: Montserrat;
    font-weight: 300;
    opacity: .8;
    color: var(--text-color-link);
    font-size:1.5vw;
    z-index: 11;
    margin-top: 10vh;
   }
   @media (max-width:768px) {
    .container{
        padding-top: 5vh;
    
    }
    .header{
        font-size:6vw;
        text-align: center;
       }
    .description{
    width: 90%;
    font-size:3.5vw;
    }
    .description2{
        width: 80%;
        font-size:3.5vw;
        margin-bottom: 5vh;
    }
    .img{
        height: auto;
        width: 90%;
    }
   }