.container{
    width: 100%;
    height: auto;
    padding:10vh 0 15vh 0;
    background-color: #171717;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.container::before{
    z-index: 0;
    content: '';
    width: 100vw;
    height: 100vw;
    position: absolute;
    background:radial-gradient(rgb(9, 24, 44),transparent,transparent);
    /* animation: animate 15.5s linear infinite;; */
    
}
@keyframes animate
{
    0%
    {
        transform: translate(0vw,-200vh);
    }
    20%
    {
        transform: translate(-30vw,10vw);
    }
    40%
    {
        transform: translate(30vw,20vw);
    }
    60%
    {
        transform: translate(-30vw,10vh);
    }
    80%
    {
        transform: translate(30vw,-100vh);
    }
    100%
    {
        transform: translate(0vw,-200vh);
    }
}
.header{
    height: auto;
    font-family: var(--ffont-header);
    color: var(--text-color-link);
    font-size:3.5vw;
    z-index: 1;
}
.nestedContainer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.name{
    height: auto;
    font-family: var(--ffont-header);
    color: var(--text-color-link);
    font-size:2.1vw;
    z-index: 1;
    margin-top: 0vh;
    opacity: .9;
}
.name1{
    height: auto;
    font-family: var(--ffont-header);
    color: var(--text-color-link);
    font-size:1.15vw;
    z-index: 1;
    margin-top: 5vh;
    opacity: .8;
    font-weight: 800;
    color:#3A4CFF;
    z-index: 11;
}
.mail{
    height: auto;
    font-family: var(--ffont-header);
    color: var(--text-color-link);
    font-size:2.1vw;
    z-index: 1;
    opacity: 1;
    text-decoration: none;
    font-weight: bold;
    transition: transform .4s ease;
}
.mail:hover{
    transform: scale(103%);
}
.number:hover{
    transform: scale(103%);
}
.number{
    height: auto;
    font-family: var(--ffont-header);
    color: var(--text-color-link);
    font-size:2.1vw;
    z-index: 1;
    opacity: .7;
    text-decoration: none;
    font-weight: bold;
    margin-top: 3vh;
    transition: transform .4s ease;
}
.number span{
    color: #3A4CFF;
}
.adress{
    height: auto;
    font-family:'Montserrat', sans-serif;
    color: var(--text-color-link);
    font-size:1.5vw;
    z-index: 1;
    width: 60%;
    text-align: center;
    font-weight: 100;
    margin-top: 0vh;
}
.button{
    height: 8vh;
    width: 80%;
    background-color: rgb(17, 49, 73);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 10vh;
    margin-top: 5vh;
    font-family: Montserrat;
    font-weight: bold;
    color: rgb(126, 161, 169);
    /* border: 1px solid rgb(28, 58, 72); */
    transition: transform .4s ease, color .4s ease,background-color .4s ease;
}
.button:hover{
    transform: scale(105%);
    cursor: pointer;
    /* color: rgb(161, 200, 209); */
    background-color: rgb(27, 81, 122);
}
.formContainer{
    z-index: 11;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Form */
.ModalForm{
    height: 70%;
    width: 100%;
    text-align: center;
}
.ModalInputName{
    width: 80%;
    height: 50px;
    margin-top: 4vh;
    border-radius: 5vh;
    border: 1px solid #375875;
    text-align: center;
    background:transparent;
    color:#cee8f0d4;
    font-size: 1.8vw;
    font-family: 'Roboto Slab', serif;
    box-sizing: border-box;
}
.ModalInputName::placeholder{
    color: #cee8f0d4;
}
.ModalInputName:focus{
    outline: none;
}
.modalinputArea{
    box-sizing: border-box;
    width: 80%;
    height: 20vh;
    margin-top: 4vh;
    border-radius: 5vh;
    border: 1px solid #375875;
    text-align: center;
    background:transparent;
    color:#cee8f0d4;
    font-size: 1.8vw;
    font-family: 'Roboto Slab', serif;
    display: flex;
    align-items: center;
    justify-content: center;
    resize: none;
    padding: 5vh 5vh;
    box-sizing: border-box;
}
.modalinputArea::placeholder{
    color: #cee8f0d4;
}
.modalinputArea:focus{
    outline: none;
}
.ModalInputPhone{
    width: 80%;
    height: 50px;
    margin-top: 30px;
    border-radius: 5vh;
    border: 1px solid #375875;
    text-align: center;
    background: transparent;
    color: #cee8f0d4;
    font-size: 1.8vw;
    font-family: 'Roboto Slab', serif;
    box-sizing: border-box;
}
.ModalInputPhone::placeholder{
    color: #cee8f0d4;
}
.ModalInputPhone:focus{
    outline: none;
}
::-webkit-input-placeholder {color:#1d0e0c;}
::-moz-placeholder          {color:#1d0e0c;}/* Firefox 19+ */
:-moz-placeholder           {color:#1d0e0c;}/* Firefox 18- */
:-ms-input-placeholder      {color:#1d0e0c;}
::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
.ModalFormButton{
    height: 50px;
    width: 82%;
    background: linear-gradient(rgba(146, 219, 250, 0.407), rgba(1, 33, 74, 0.385));
    opacity: .9;
    border-radius: 20px;
    border: none;
    color: rgba(255, 255, 255, 0.564);
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    transition: all 0.4s ease;
}
.ModalFormButton:hover{
    background: linear-gradient(rgba(146, 219, 250, rgba(22, 65, 121, 0.549)65, 121));
    opacity: 1;
    transform: scale(101%);
    cursor: pointer;
    color:#eafeff ;
}
.button{
    font-size: 1.5vw;
    color:white;
    font-family: Montserrat;
    font-weight: 700;
    z-index: 11;
    cursor: pointer;
    opacity: .8;
    transition: all.3s;
    background-color: rgba(128, 128, 128, 0.103);
    border-radius: 15vh;
}
.button:hover{
    transform: scale(102%);
    opacity: 1;
    background-color:#2B3BD9;
}
@media (max-width: 780px){
    .container{
        padding:0vh 0 10vh 0;
    }
    .header{
        font-size:7vw;
        z-index: 1;
        opacity: .8;
    }
    .name{
        font-size:4.5vw;
        margin-top: 0vh;
        opacity: .8;
    }
    .name1{
        font-size:3vw;
        margin-top: 5vh;
        opacity: .8;
    }
    .mail{
        font-size:3.5vw;
    }
    .number{
        font-size:3.5vw;
        z-index: 1;
        opacity: .7;
    }
    .adress{
        font-size:3.5vw;
        z-index: 1;
        width: 75%;

    }
    .button{
        height: 8vh;
        width: 90%;
        margin-top: 5vh;
        font-size: 4vw;
    }
    .formContainer{
        z-index: 11;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .ModalInputName{
        height: 5vh;
        width: 90%;
        margin-top: 4vh;
        font-size:3.8vw;
    }
    .modalinputArea{
        width: 90%;
        font-size: 3.8vw;
        box-sizing: border-box;
    }
    .ModalInputPhone{
        height: 5vh;
        width: 90%;
        margin-top: 3vh;
        font-size: 3.8vw;
    }
    .ModalFormButton{
        height: 5vh;
        width: 90%;
        margin-top: 3vh;
        font-size: 3.8vw;

    }
    .nestedContainer{
        width: 100%;
        flex-direction: column;
    }
    .contactContainer{
        width: 90%;

    }
}