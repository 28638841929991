.container{
    width: 100%;
    height: auto;
    padding:0 0  10 0vh;
    gap: 7vh;
    background-color: #171717;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.specialCard::before{
    z-index: 0;
    content: '';
    width: 100vw;
    height: 100vw;
    position: absolute;
    background:radial-gradient(rgb(16, 31, 51),transparent,transparent);
    /* animation: animate 15.5s linear infinite; */
    
}
.specialCard .header{
    z-index: 11;
}
.vector{
    position: absolute;
    height: 87%;
    width: 25%;
    top: 8%;
    left: 27%;
    z-index: 0;
}
.vectorMask{
    background-color: #171717;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    bottom: 0;
}
@keyframes animate
{
    0%
    {
        transform: translate(0vw,-200vh);
    }
    20%
    {
        transform: translate(-30vw,0vw);
    }
    40%
    {
        transform: translate(30vw,100vw);
    }
    60%
    {
        transform: translate(-30vw,0vh);
    }
    80%
    {
        transform: translate(30vw,-100vh);
    }
    100%
    {
        transform: translate(0vw,-200vh);
    }
}
.workHeader{
    height: auto;
    font-family: Montserrat;
    font-weight: bold;
    color: var(--text-color-link);
    font-size:3.5vw;
    z-index: 1;
}
.card{
    width: 60%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1vh;
    z-index: 11;
    /* border: 1px solid rgb(52, 58, 66); */
    border-radius: 5vh;
    padding:3vh 0;
}
.imgContainer{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img{
height: 20vh;
opacity: 1;
transition: transform .4s ease, opacity .4s ease;
}
/* .img:hover{
    transform: scale(105%);
    opacity: 1;
} */
.textContainer{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.header{
 color: white;
 font-size: 1.9vw;
 height: 20%;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 padding-right: 3vw;
 font-family: Montserrat;
 font-weight: bold;
}
.header span{
    margin-right: 1vw;
    margin-top: 1vh;
    height: 6vh;
    width: 6vh;
    background: #3A4CFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10vh;
    opacity: .8;
}
.description{
    color: white;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 10vw;
    opacity: .9;
    font-size: 1.2vw;
    font-family: Montserrat;
    font-weight: 100;
}
.substrat1{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:-40vh;
    left: 0;
    z-index: 0;
}
.substrat2{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:160vh;
    left: 0;
    z-index: 0;
}
.substrat3{
    position: absolute;
    width: 80vw;
    height: 100vh;
    top:260vh;
    left: 0;
    transform: rotate(10deg);
    z-index: 0;
}

@media (max-width: 780px){
    .container{
        padding: 10vh 0 1vh 0;
    }
    .workHeader{
        font-size:7vw;
        z-index: 1;
        opacity: .8;
        width: 90%;
        text-align: center;
    }
    .card{
        width: 90%;
        height: auto;
        flex-direction: column;
        margin-top: 1vh;
        padding:10vh 0;
        backdrop-filter: blur(45px);
        border: 1px solid rgb(52, 58, 66);
    }
    .imgContainer{
        width: 100%;
        height: 100%;

    }
    .img{
    height: auto;
    width: 90%;
    opacity: .7;
    }
    .textContainer{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
    }
    .header{
     font-size: 4vw;
     height: auto;
     width: 90%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding-right: 0vw;
     gap: 8vh;
     text-align: center;
    }
    .header span{
        margin-right: 0vw;
        margin-top: 1vh;
        height: 10vh;
        width: 10vh;
    }
    .description{
        color: white;
        height: auto;
        width: 90%;
        justify-content: center;
        padding-right: 0vw;
        font-size: 3.5vw;
        text-align: center;
    }
}