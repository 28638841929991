@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.slider {
    width: 70%;
    height:auto;
    margin-top: 10vh;
    z-index: 11;
    padding: 5vh 0;
  }
  
  .slider img {
    height: 40vh;
    margin: 5vh auto;
    
  }
  
  .slide {
    transform: scale(0.5);
    transition: transform 300ms;
    opacity: 0.5;
    /* border: 1px solid rgb(68, 80, 88); */
    border-radius: 10vh;
    margin: 5vh 0;
    /* backdrop-filter: blur(45px); */
  }
  
  .activeSlide {
    transform: scale(1.1);
    opacity: 1;
  }
  
  .arrow {
    /* background-color: #fff; */
    position: absolute;
    cursor: pointer;
    z-index: 10;
    color: white;
    font-size: 30px;
  }
  
  .arrow svg {
    transition: color 300ms;
  }
  
  .arrow svg:hover {
    color: #3871da;
  }
  
  .next {
    right: 0%;
    top: 40%;
  }
  
  .prev {
    left: 0%;
    top: 40%;
  }


.container{
    background-color: #171717;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh 0;
    position: relative;
}
.container::before{
    z-index: 0;
    content: '';
    top:0;
    width: 70vw;
    height: 70vw;
    position: absolute;
    background:radial-gradient(rgb(10, 28, 51),transparent,transparent);
    /* animation: animate 10.5s linear infinite;; */
    
}
@keyframes animate
{
    0%
    {
        transform: translate(0vw,-50vh);
    }
    20%
    {
        transform: translate(-30vw,-30vw);
    }
    40%
    {
        transform: translate(30vw,-30vw);
    }
    60%
    {
        transform: translate(-30vw,-70vh);
    }
    80%
    {
        transform: translate(30vw,-70vh);
    }
    100%
    {
        transform: translate(0vw,-50vh);
    }
}
.header{
    height: auto;
    font-family: Montserrat;
    font-weight: bold;
    color: var(--text-color-link);
    font-size:3.5vw;
    z-index: 11;
   }
.buttonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 7vh;
    width: 100%;
    gap: 5vw;
    margin-top: 10vh;
}
.button{
    height: 100%;
    width: 30%;
    background-color: rgb(17, 49, 73);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 10vh;
    margin-top: 5vh;
    font-family: Montserrat;
    font-weight: bold;
    color: rgb(126, 161, 169);
    border: 1px solid rgb(28, 58, 72);
    transition: transform .4s ease, color .4s ease,background-color .4s ease;
    text-decoration: none;
}
.button:hover{
    transform: scale(105%);
    cursor: pointer;
    color: rgb(161, 200, 209);
    background-color: rgb(27, 81, 122);
}
.button{
    font-size: 1.1vw;
    color:white;
    font-family: Montserrat;
    font-weight: 700;
    z-index: 11;
    cursor: pointer;
    opacity: .8;
    transition: all.3s;
    background-color: rgba(128, 128, 128, 0.103);
    border-radius: 15vh;
    border:none;
}
.button:hover{
    transform: scale(102%);
    opacity: 1;
    color: white;
    background-color:#2B3BD9;
}
@media (max-width: 1024px){
    .button{
        font-size: 1.3vw;
    }
}
@media (max-width: 780px){
    .slider{
        width: 100%;
    }
    .arrow {
        display: none;
      }
    .header{
        margin-top: 10vh;
        font-size: 6vw;
    }
    .buttonContainer{
        flex-direction: column;
        height: auto;
        gap: 0vh;
    }
    .button{
        width: 80%;
        height: 8vh;
        font-size: 4vw;
    }
    .container{
        padding: 0 0 10vh 0;

    }
    .nestedContainer{
        flex-direction: column;
        gap: 5vw;
        border: 1px solid rgb(35, 45, 55);
        width: 90%;
        border-radius: 10vw;
        padding: 7vh 0;
        backdrop-filter: blur(45px);
    }
    .imgContainer{
        width: 100%;
        justify-content: center;
    }
    .img{
        width: 80%;
    }
    .textContainer{
        width: 100%;
        justify-content: center;
    }
    .description{
        width: 85%;
        font-size:5vw;
        text-align: center;
    }
    .imgContainer1{
        width: 100%;
        justify-content: center;
    }
    .img1{
        width: 80%;
    }
    .textContainer1{
        width: 100%;
        justify-content: center;
    }
    .description1{
        width: 85%;
        font-size:5vw;
        text-align: center;
    }
    .buttonContainer{
        margin-top:0vh;
    }
}