.container{
    position: fixed;
    z-index: 1111;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100vh;
}
.nav{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6vh;
}
.link{
    font-family: Montserrat;
    font-weight: bold;
    color:white;
    font-size: 8vw;
    text-decoration: none;
    opacity: .8;
}
.x{
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-size: 10vw;
    height: 15vh;
    width: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img{
    height: 30%;
    opacity: .8;
}