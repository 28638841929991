body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* Унивкерсальный размер */
  --wh:calc(1vh+1vw);

  /* Цвета */
  --text-color-link:white;
  --text-color-link-active:#6b76f7;
  

  /* Шрифты */
  --ffont-link:  font-family: Georgia, 'Times New Roman', Times, serif;
}
.App{
  position: relative;
  overflow: hidden;
}
.webHub{
  color: rgb(0, 0, 0);
  position: absolute;
  bottom: -100px;
  left: 0;
}