.container{
    width: 100%;
    height: 12vh;
    background-color: rgba(23, 23, 23,0);

    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 111;
}
.linkContainer{
    width: 60%;
    height: 100%;
}
.nav{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 3vw;
    padding: 0 3vw 0 0;
    box-sizing: border-box;
}

.link{
    color: var(--text-color-link);
    font-size: 1vw;
    font-family: var(--ffont-link);
    transition: transform .4s ease, color .4s ease,opacity .4s ease;
    opacity: .8;
    text-decoration: none;
}
/* .link span{
    color:#3A4CFF;
    transform: scale(110%);
    font-size: 1.2vw;
} */
.link:hover{
    transform: scale(105%);
    cursor: pointer;
    color:#3A4CFF;
    opacity: 1;
}
.imgContainer{
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3vw;
}
.imgLink{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    height: 50%;
    transition: transform .4s ease,opacity .4s ease;
    opacity: 0;
}
.logo:hover{
    transform: scale(103%);
    cursor: pointer;
    opacity: 1;
}
.buttonButger{
    display: none;
}
@media (max-width: 1000px){
    .linkContainer{
        width: 50%;
    }
    .nm{
        display: none;
    }
    .link{
        font-size: 2vw;
        transition: transform .4s ease, color .4s ease;
    }
    .logo{
        height: 40%;
        transition: transform .4s ease,opacity .4s ease;
        opacity: 0;
    }
}
@media (max-width: 780px){
    .linkContainer{
        display: none;
    }
    .nav{
        display: none;
    }
    .container{
        height: 12vh;
    }
    .logo{
        height: 40%;
        opacity: 1;
    }
    .buttonButger{
        display: flex;
        font-size: 10vw;
        color: white;
        transform: rotate(90deg);
        margin-right: 10vw;
        opacity: .8;
    }
}